import React from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

function GeotagFilters({
  years,
  blocks,
  panchayats,
  selectedYear,
  selectedBlock,
  selectedPanchayat,
  onYearChange,
  onBlockChange,
  onPanchayatChange,
}) {
  return (
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <InputLabel>Year</InputLabel>
          <Select value={selectedYear} onChange={onYearChange}>
            <MenuItem value="">All Years</MenuItem>
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <InputLabel>Block</InputLabel>
          <Select value={selectedBlock} onChange={onBlockChange}>
            <MenuItem value="">All Blocks</MenuItem>
            {blocks.map((block) => (
              <MenuItem key={block} value={block}>
                {block}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth>
          <InputLabel>Panchayat</InputLabel>
          <Select
            value={selectedPanchayat}
            onChange={onPanchayatChange}
            disabled={!selectedBlock}
          >
            <MenuItem value="">All Panchayats</MenuItem>
            {panchayats.map((panchayat) => (
              <MenuItem key={panchayat} value={panchayat}>
                {panchayat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default GeotagFilters;
