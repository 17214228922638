import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
} from "@mui/material";
import api from "../services/api";

function PaymentRecipientsSummary({ filters }) {
  const [data, setData] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [detailedData, setDetailedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.fetchPaymentRecipientsSummary(filters);
      setData(result);
    };
    fetchData();
  }, [filters]);

  useEffect(() => {
    if (selectedRecipient) {
      const fetchDetailedData = async () => {
        const result = await api.fetchPaymentRecipientDetails({
          ...filters,
          payment_recipient: selectedRecipient,
        });
        setDetailedData(result);
      };
      fetchDetailedData();
    }
  }, [selectedRecipient, filters]);

  const formatAmount = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleRecipientClick = (recipient) => {
    setSelectedRecipient(recipient);
  };

  const handleBackClick = () => {
    setSelectedRecipient(null);
  };

  if (selectedRecipient) {
    return (
      <Box>
        <Button onClick={handleBackClick}>Back to Summary</Button>
        <Typography variant="h6" gutterBottom>
          Details for {selectedRecipient}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: 400, overflow: "auto" }}
        >
          <Table stickyHeader aria-label="payment recipient details table">
            <TableHead>
              <TableRow>
                <TableCell>Block</TableCell>
                <TableCell>Gram Panchayat</TableCell>
                <TableCell>Village</TableCell>
                <TableCell>Work Type</TableCell>
                <TableCell>Work Description</TableCell>
                <TableCell align="right">Total Amount</TableCell>
                <TableCell align="right">Payment Count</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Latest Payment Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailedData.map((row) => (
                <TableRow key={row.ViewMoreLink}>
                  <TableCell>{row.Block}</TableCell>
                  <TableCell>{row.Gram_Panchayat}</TableCell>
                  <TableCell>{row.Village}</TableCell>
                  <TableCell>{row.Work_Type}</TableCell>
                  <TableCell>{row.Work_Description_English}</TableCell>
                  <TableCell align="right">
                    {formatAmount(row.Total_Amount)}
                  </TableCell>
                  <TableCell align="right">{row.Payment_Count}</TableCell>
                  <TableCell>{row.Status}</TableCell>
                  <TableCell>{row.Latest_Payment_Date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Payment Recipients Summary
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 300, overflow: "auto" }}
      >
        <Table stickyHeader aria-label="payment recipients table">
          <TableHead>
            <TableRow>
              <TableCell>Payment Recipient</TableCell>
              <TableCell align="right">Total Amount</TableCell>
              <TableCell align="right">Payment Count</TableCell>
              <TableCell align="right">Unique Works</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.Payment_Recipient}
                hover
                onClick={() => handleRecipientClick(row.Payment_Recipient)}
                style={{ cursor: "pointer" }}
              >
                <TableCell component="th" scope="row">
                  {row.Payment_Recipient}
                </TableCell>
                <TableCell align="right">
                  {formatAmount(row.Total_Amount)}
                </TableCell>
                <TableCell align="right">{row.Payment_Count}</TableCell>
                <TableCell align="right">{row.Unique_Works}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PaymentRecipientsSummary;
