import React, { useState, useEffect } from "react";
import { Typography, Box, Grid, Paper } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import api from "../services/api";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#8dd1e1",
  "#a4de6c",
  "#d0ed57",
];

function WorkStatusAndKPIs({ year, block, panchayat }) {
  const [statusData, setStatusData] = useState([]);
  const [kpiData, setKpiData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statusResult = await api.fetchWorkStatusDistribution(
          year,
          block,
          panchayat
        );
        // Sort by count and take top 10
        const topStatusData = statusResult
          .sort((a, b) => b.count - a.count)
          .slice(0, 10)
          .map((item, index) => ({
            ...item,
            color: COLORS[index % COLORS.length],
          }));
        setStatusData(topStatusData);

        const kpiResult = await api.fetchWorkKPIs(year, block, panchayat);
        setKpiData(kpiResult);
      } catch (error) {
        console.error("Error fetching work status and KPI data:", error);
      }
    };
    fetchData();
  }, [year, block, panchayat]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      notation: "compact",
      compactDisplay: "short",
    }).format(value);
  };

  const formatCurrencyInCrores = (value) => {
    const croreValue = value / 100;
    return `${new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(croreValue)} Cr`;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`${payload[0].payload.work_purpose_status}: ${payload[0].value}`}</p>
          <p>{`${(payload[0].percent * 100).toFixed(2)}%`}</p>
        </div>
      );
    }
    return null;
  };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul style={{ listStyle: "none", padding: 0, marginTop: "20px" }}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: entry.color,
                marginRight: "5px",
              }}
            ></div>
            <span style={{ fontSize: "12px" }}>
              {entry.payload.work_purpose_status}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    work_purpose_status,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={10}
      >
        {`${work_purpose_status}: ${(percent * 100).toFixed(2)}%`}
      </text>
    );
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Work Status Distribution and KPIs
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Key Performance Indicators
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">Total Works</Typography>
                <Typography variant="h6">{kpiData.totalWorks || 0}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Total Estimated Cost</Typography>
                <Typography variant="h6">
                  {formatCurrencyInCrores(kpiData.totalEstimatedCost || 0)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Total Expenditure</Typography>
                <Typography variant="h6">
                  {formatCurrency(kpiData.totalExpenditure || 0)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, height: 600 }}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={statusData}
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="count"
                  label={renderCustomLabel}
                  labelLine={false}
                >
                  {statusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  content={renderLegend}
                  align="left"
                  verticalAlign="middle"
                  layout="vertical"
                />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WorkStatusAndKPIs;
