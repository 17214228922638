import React, { useState, useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import api from "../services/api";
import DetailView from "./DetailView";

function FundingDepartmentCharts({ filters }) {
  const [countData, setCountData] = useState([]);
  const [amountData, setAmountData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [detailedData, setDetailedData] = useState([]);
  const [viewType, setViewType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countResult = await api.fetchFundingDepartmentCount(filters);
        setCountData(
          countResult.map((item) => ({
            ...item,
            Count: Number(item.Count) || 0,
            Percentage: Number(item.Percentage) || 0,
          }))
        );

        const amountResult = await api.fetchFundingDepartmentAmount(filters);
        setAmountData(
          amountResult.map((item) => ({
            ...item,
            Total_Sanctioned_Amount: Number(item.Total_Sanctioned_Amount) || 0,
            Percentage: Number(item.Percentage) || 0,
          }))
        );
      } catch (error) {
        console.error("Error fetching funding department data:", error);
        setCountData([]);
        setAmountData([]);
      }
    };
    fetchData();
  }, [filters]);

  useEffect(() => {
    if (selectedDepartment) {
      const fetchDetailedData = async () => {
        try {
          const result = await (viewType === "count"
            ? api.fetchFundingDepartmentCountDetails
            : api.fetchFundingDepartmentAmountDetails)({
            ...filters,
            funding_department: selectedDepartment,
          });
          setDetailedData(result);
        } catch (error) {
          console.error("Error fetching detailed data:", error);
          setDetailedData([]);
        }
      };
      fetchDetailedData();
    }
  }, [selectedDepartment, filters, viewType]);

  const handleClick = (entry, type) => {
    setSelectedDepartment(entry.Funding_Department);
    setViewType(type);
  };

  const handleBack = () => {
    setSelectedDepartment(null);
    setViewType(null);
  };

  const formatValue = (value) => {
    return typeof value === "number" ? value.toFixed(2) : "0.00";
  };

  const renderChart = (data, title, dataKey, type) => (
    <Box height={400}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {data.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            layout="vertical"
            margin={{ top: 5, right: 50, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" tick={{ fontSize: "0.8rem" }} />
            <YAxis
              dataKey="Funding_Department"
              type="category"
              width={240}
              tick={{ fontSize: "0.8rem" }}
            />
            <Tooltip formatter={(value) => `${formatValue(value)}%`} />
            <Legend />
            <Bar
              dataKey={dataKey}
              fill="#8884d8"
              onClick={(entry) => handleClick(entry, type)}
            >
              <LabelList
                dataKey="Percentage"
                position="right"
                formatter={(value) => `${formatValue(value)}%`}
                style={{ fontSize: "0.8rem" }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Typography variant="body1">
          No data available for the selected filters.
        </Typography>
      )}
    </Box>
  );

  if (selectedDepartment) {
    return (
      <DetailView
        title={`Details for Funding Department: ${selectedDepartment}`}
        data={detailedData}
        onBack={handleBack}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        {renderChart(
          countData,
          "Funding Department Distribution by Count",
          "Count",
          "count"
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {renderChart(
          amountData,
          "Funding Department Distribution by Amount",
          "Total_Sanctioned_Amount",
          "amount"
        )}
      </Grid>
    </Grid>
  );
}

export default FundingDepartmentCharts;
