import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import api from "../services/api";

function StatusByYearChart({ filters }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.fetchStatusByYear(filters);
        setData(result);
      } catch (error) {
        console.error("Error fetching status by year:", error);
        setData([]);
      }
    };
    fetchData();
  }, [filters]);

  const getYAxisDomain = () => {
    if (data.length === 0) return [0, "auto"];
    const maxCount = Math.max(
      ...data.flatMap((item) => [
        item.Ongoing,
        item.Not_Started,
        item.Completed,
      ])
    );
    return [0, Math.ceil(maxCount * 1.1)]; // Add 10% padding to the top
  };

  return (
    <Box height={400}>
      <Typography variant="h6" gutterBottom>
        Status by Year
      </Typography>
      {data.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Approval_Year" />
            <YAxis domain={getYAxisDomain()} />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="Ongoing"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="Not_Started"
              stroke="#82ca9d"
              activeDot={{ r: 8 }}
            />
            <Line
              type="monotone"
              dataKey="Completed"
              stroke="#ffc658"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Typography>No data available</Typography>
      )}
    </Box>
  );
}

export default StatusByYearChart;
