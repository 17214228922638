import axios from "axios";

// const API_URL = "http://localhost:5002/api"; // Use local server URL
const API_URL = "https://prd.anshumanraj.in/api"; // Comment out production URL

const api = {
  fetchBlocks: async () => {
    try {
      const response = await axios.get(`${API_URL}/blocks`);
      return response.data;
    } catch (error) {
      console.error("Error fetching blocks:", error);
      return [];
    }
  },

  fetchPanchayats: async (block) => {
    try {
      const response = await axios.get(`${API_URL}/panchayats`, {
        params: { block },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching panchayats:", error);
      return [];
    }
  },

  fetchVillages: async (block, panchayat) => {
    try {
      const response = await axios.get(`${API_URL}/villages`, {
        params: { block, panchayat },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching villages:", error);
      return [];
    }
  },

  fetchTotalWorks: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/total-works`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching total works:", error);
      return { Total_Works: 0 };
    }
  },

  fetchWorkTypeCount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/work-type-count`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching work type count:", error);
      return [];
    }
  },

  fetchFundingDepartmentCount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/funding-department-count`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching funding department count:", error);
      return [];
    }
  },

  fetchMajorSchemeCount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/major-scheme-count`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching major scheme count:", error);
      return [];
    }
  },

  // Add these new methods to your api.js file

  fetchPanchayatExpenditureDetails: async (params) => {
    try {
      const response = await axios.get(
        `${API_URL}/panchayat-expenditure-details`,
        { params }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching panchayat expenditure details:", error);
      return [];
    }
  },

  fetchBlockExpenditureDetails: async (params) => {
    try {
      const response = await axios.get(`${API_URL}/block-expenditure-details`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching block expenditure details:", error);
      return [];
    }
  },

  fetchYearWiseWorkCount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/year-wise-work-count`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching year-wise work count:", error);
      return [];
    }
  },

  fetchYearWiseWorkAmount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/year-wise-work-amount`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching year-wise work amount:", error);
      return [];
    }
  },

  // Add these new methods to your api.js file

  fetchMajorSchemeCountDetails: async (params) => {
    try {
      const response = await axios.get(
        `${API_URL}/major-scheme-count-details`,
        { params }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching major scheme count details:", error);
      return [];
    }
  },
  fetchFundingDepartments: async () => {
    try {
      const response = await axios.get(`${API_URL}/funding-departments`);
      return response.data;
    } catch (error) {
      console.error("Error fetching funding departments:", error);
      return [];
    }
  },
  fetchMajorSchemeAmountDetails: async (params) => {
    try {
      const response = await axios.get(
        `${API_URL}/major-scheme-amount-details`,
        { params }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching major scheme amount details:", error);
      return [];
    }
  },

  fetchWorkTypes: async () => {
    try {
      const response = await axios.get(`${API_URL}/work-types`);
      return response.data;
    } catch (error) {
      console.error("Error fetching work types:", error);
      return [];
    }
  },

  fetchConvergenceStatusCount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/convergence-status-count`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching convergence status count:", error);
      return [];
    }
  },

  fetchApprovalAgenciesCount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/approval-agencies-count`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching approval agencies count:", error);
      return [];
    }
  },

  fetchStatusByYear: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/status-by-year`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching status by year:", error);
      return [];
    }
  },

  fetchTopPanchayatsByExpenditure: async (filters) => {
    try {
      const response = await axios.get(
        `${API_URL}/top-panchayats-expenditure`,
        {
          params: filters,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching top panchayats by expenditure:", error);
      return [];
    }
  },

  fetchBlocksByExpenditure: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/blocks-expenditure`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching blocks by expenditure:", error);
      return [];
    }
  },

  fetchFundingDepartmentAmount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/funding-department-amount`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching funding department amount:", error);
      return [];
    }
  },

  fetchMajorSchemeAmount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/major-scheme-amount`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching major scheme amount:", error);
      return [];
    }
  },
  fetchWorkTypeAmountDetails: async (params) => {
    try {
      const response = await axios.get(`${API_URL}/work-type-amount-details`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching work type amount details:", error);
      return [];
    }
  },

  fetchFundingDepartmentCountDetails: async (params) => {
    try {
      const response = await axios.get(
        `${API_URL}/funding-department-count-details`,
        { params }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching funding department count details:", error);
      return [];
    }
  },

  fetchFundingDepartmentAmountDetails: async (params) => {
    try {
      const response = await axios.get(
        `${API_URL}/funding-department-amount-details`,
        { params }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching funding department amount details:", error);
      return [];
    }
  },

  fetchWorkTypeByAmount: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/work-type-by-amount`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching work types by amount:", error);
      return [];
    }
  },
  fetchWorkTypeDetails: async (params) => {
    try {
      const response = await axios.get(`${API_URL}/work-type-details`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching work type details:", error);
      return [];
    }
  },

  fetchFinancialSummary: async (filters) => {
    try {
      const response = await axios.get(`${API_URL}/financial-summary`, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching financial summary:", error);
      return {};
    }
  },

  fetchPaymentRecipientDetails: async (params) => {
    try {
      const response = await axios.get(`${API_URL}/payment-recipient-details`, {
        params,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching payment recipient details:", error);
      return [];
    }
  },

  fetchPaymentRecipientsSummary: async (filters) => {
    try {
      const response = await axios.get(
        `${API_URL}/payment-recipients-summary`,
        { params: filters }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching payment recipients summary:", error);
      return [];
    }
  },
  ///////////.//////////////////
  fetchGeotagSummary: async (year, block, panchayat) => {
    try {
      const response = await axios.get(`${API_URL}/geotag/summary`, {
        params: { year, block, panchayat },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching geotag summary:", error);
      throw error;
    }
  },

  fetchGeotagYears: async () => {
    try {
      const response = await axios.get(`${API_URL}/geotag/years`);
      return response.data;
    } catch (error) {
      console.error("Error fetching geotag years:", error);
      throw error;
    }
  },
  fetchGeotagBlocks: async () => {
    try {
      const response = await axios.get(`${API_URL}/geotag/blocks`);
      return response.data;
    } catch (error) {
      console.error("Error fetching geotag blocks:", error);
      throw error;
    }
  },

  fetchGeotagPanchayats: async (block) => {
    try {
      const response = await axios.get(`${API_URL}/geotag/panchayats`, {
        params: { block },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching geotag panchayats:", error);
      throw error;
    }
  },

  fetchGeotagMapData: async (year, block, panchayat) => {
    try {
      const params = new URLSearchParams();
      if (year) params.append("year", year);
      if (block) params.append("block", block);
      if (panchayat) params.append("panchayat", panchayat);

      const response = await axios.get(
        `${API_URL}/geotag/map-data?${params.toString()}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching geotag map data:", error);
      throw error;
    }
  },
  fetchGeotagWorkDetails: async (panchayat, year) => {
    try {
      const response = await axios.get(`${API_URL}/geotag/work-details`, {
        params: { panchayat, year },
      });
      return response.data.map((work) => ({
        ...work,
        work_start_date: work.work_start_date
          ? new Date(
              work.work_start_date.split("-").reverse().join("-")
            ).toLocaleDateString("en-IN")
          : "N/A",
      }));
    } catch (error) {
      console.error("Error fetching geotag work details:", error);
      throw error;
    }
  },
  fetchGeotagThumbnail: async (filename) => {
    try {
      const response = await axios.get(
        `${API_URL}/geotag/thumbnail/${filename}`,
        {
          responseType: "blob",
        }
      );
      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error("Error fetching geotag thumbnail:", error);
      throw error;
    }
  },

  // Add these new methods to your api.js file

  fetchGeotagWorkData: async (year, block, panchayat) => {
    try {
      const params = new URLSearchParams();
      if (year) params.append("year", year);
      if (block) params.append("block", block);
      if (panchayat) params.append("panchayat", panchayat);

      const response = await axios.get(
        `${API_URL}/geotag/work-data?${params.toString()}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching geotag work data:", error);
      throw error;
    }
  },

  fetchWorkStatusDistribution: async (year, block, panchayat) => {
    try {
      const params = new URLSearchParams();
      if (year) params.append("year", year);
      if (block) params.append("block", block);
      if (panchayat) params.append("panchayat", panchayat);

      const response = await axios.get(
        `${API_URL}/geotag/work-status-distribution?${params.toString()}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching work status distribution:", error);
      throw error;
    }
  },

  fetchExpenditureSummary: async (year, block, panchayat) => {
    try {
      const params = new URLSearchParams();
      if (year) params.append("year", year);
      if (block) params.append("block", block);
      if (panchayat) params.append("panchayat", panchayat);

      const response = await axios.get(
        `${API_URL}/geotag/expenditure-summary?${params.toString()}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching expenditure summary:", error);
      throw error;
    }
  },
  fetchWorkKPIs: async (year, block, panchayat) => {
    try {
      const params = new URLSearchParams();
      if (year) params.append("year", year);
      if (block) params.append("block", block);
      if (panchayat) params.append("panchayat", panchayat);

      const response = await axios.get(
        `${API_URL}/geotag/work-kpis?${params.toString()}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching work KPIs:", error);
      throw error;
    }
  },

  /////////////..........//////////////
};

export default api;
