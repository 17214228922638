import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import api from "../services/api";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#ffc658",
];

function ConvergenceStatusChart({ filters }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.fetchConvergenceStatusCount(filters);
        const total = result.reduce(
          (sum, item) => sum + (Number(item.Count) || 0),
          0
        );
        const dataWithPercentage = result.map((item) => ({
          ...item,
          Count: Number(item.Count) || 0,
          Percentage:
            total > 0
              ? (((Number(item.Count) || 0) / total) * 100).toFixed(2)
              : "0.00",
        }));
        setData(dataWithPercentage);
      } catch (error) {
        console.error("Error fetching convergence status data:", error);
        setData([]);
      }
    };
    fetchData();
  }, [filters]);

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    if (typeof percent !== "number") return null;

    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const tooltipFormatter = (value, name, props) => {
    if (props && props.payload && props.payload.Percentage) {
      return [`${props.payload.Percentage}%`, name];
    }
    return ["0%", name];
  };

  return (
    <Box height={300}>
      <Typography variant="h6" gutterBottom>
        Convergence Status Distribution
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="Count"
            nameKey="Convergence_Status"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip formatter={tooltipFormatter} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default ConvergenceStatusChart;
