import React, { useState, useEffect } from "react";
import { Typography, Paper } from "@mui/material";
import api from "../services/api";

function TotalWorks({ filters }) {
  const [totalWorks, setTotalWorks] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const data = await api.fetchTotalWorks(filters);
      setTotalWorks(data.Total_Works);
    };
    fetchData();
  }, [filters]);

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" color="textSecondary" gutterBottom>
        Total Works
      </Typography>
      <Typography variant="h3" color="primary">
        {totalWorks.toLocaleString()}
      </Typography>
    </Paper>
  );
}

export default TotalWorks;
