import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import api from "../services/api";
import DetailView from "./DetailView";

function WorkTypeChart({ filters }) {
  const [data, setData] = useState([]);
  const [selectedWorkType, setSelectedWorkType] = useState(null);
  const [detailedData, setDetailedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.fetchWorkTypeCount(filters);
      const sortedData = result.sort((a, b) => b.Count - a.Count).slice(0, 10);
      const total = sortedData.reduce((sum, item) => sum + item.Count, 0);
      const dataWithPercentage = sortedData.map((item) => ({
        ...item,
        Percentage: ((item.Count / total) * 100).toFixed(2),
      }));
      setData(dataWithPercentage);
    };
    fetchData();
  }, [filters]);

  useEffect(() => {
    if (selectedWorkType) {
      const fetchDetailedData = async () => {
        const result = await api.fetchWorkTypeDetails({
          ...filters,
          work_type: selectedWorkType,
        });
        setDetailedData(result);
      };
      fetchDetailedData();
    }
  }, [selectedWorkType, filters]);

  const handleClick = (entry) => {
    setSelectedWorkType(entry.Work_Type);
  };

  const handleBack = () => {
    setSelectedWorkType(null);
  };

  if (selectedWorkType) {
    return (
      <DetailView
        title={`Details for Work Type: ${selectedWorkType}`}
        data={detailedData}
        onBack={handleBack}
      />
    );
  }

  return (
    <Box height={400}>
      <Typography variant="h6" gutterBottom>
        Top 10 Work Types
      </Typography>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          data={data}
          layout="vertical"
          margin={{ top: 5, right: -150, left: 150, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            tick={{ fontSize: "0.7rem" }}
            domain={[0, 100]}
            unit="%"
          />
          <YAxis
            dataKey="Work_Type"
            type="category"
            width={140}
            tick={{ fontSize: "0.7rem" }}
          />
          <Tooltip formatter={(value) => `${value}%`} />
          <Legend />
          <Bar dataKey="Percentage" fill="#8884d8" onClick={handleClick}>
            <LabelList
              dataKey="Percentage"
              position="right"
              formatter={(value) => `${value}%`}
              style={{ fontSize: "0.7rem" }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default WorkTypeChart;
