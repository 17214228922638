import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import api from "../services/api";

function ExpenditureSummaryChart({ year, block, panchayat }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.fetchExpenditureSummary(
          year,
          block,
          panchayat
        );
        const formattedData = [
          { name: "Unskilled", amount: result.total_unskilled / 10000000 },
          {
            name: "Semi-Skilled",
            amount: result.total_semi_skilled / 10000000,
          },
          { name: "Skilled", amount: result.total_skilled / 10000000 },
          { name: "Material", amount: result.total_material / 10000000 },
          { name: "Contingency", amount: result.total_contingency / 10000000 },
        ];
        setData(formattedData);
      } catch (error) {
        console.error("Error fetching expenditure summary:", error);
      }
    };
    fetchData();
  }, [year, block, panchayat]);

  return (
    <Box height={300}>
      <Typography variant="h6" gutterBottom>
        Expenditure Summary (in Crores)
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip formatter={(value) => `₹${value.toFixed(2)} Cr`} />
          <Legend />
          <Bar dataKey="amount" fill="#8884d8">
            <LabelList
              dataKey="amount"
              position="top"
              formatter={(value) => `₹${value.toFixed(2)} Cr`}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default ExpenditureSummaryChart;
