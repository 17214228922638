import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  AppBar,
  Toolbar,
  CssBaseline,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import TotalWorks from "./TotalWorks";
import WorkTypeChart from "./WorkTypeChart";
import WorkTypesByAmountChart from "./WorkTypesByAmountChart";
import FundingDepartmentCharts from "./FundingDepartmentCharts";
import MajorSchemeCharts from "./MajorSchemeCharts";
import ConvergenceStatusChart from "./ConvergenceStatusChart";
import ApprovalAgenciesChart from "./ApprovalAgenciesChart";
import StatusByYearChart from "./StatusByYearChart";
import FinancialSummary from "./FinancialSummary";
import PaymentRecipientsSummary from "./PaymentRecipientsSummary";
import TopPanchayatsChart from "./TopPanchayatsChart";
import BlocksExpenditureChart from "./BlocksExpenditureChart";
import YearWiseWorkCountChart from "./YearWiseWorkCountChart";
import YearWiseWorkAmountChart from "./YearWiseWorkAmountChart";
import api from "../services/api";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontWeight: 700,
      color: "#ffffff",
    },
    h5: {
      fontWeight: 600,
      color: "#333333",
      marginBottom: "1rem",
    },
    h6: {
      fontWeight: 600,
      color: "#1976d2",
      marginBottom: "1rem",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 3px 15px rgba(0,0,0,0.1)",
          borderRadius: "10px",
          height: "100%",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1976d2",
        },
      },
    },
  },
});

function Dashboard() {
  const [blocks, setBlocks] = useState([]);
  const [panchayats, setPanchayats] = useState([]);
  const [villages, setVillages] = useState([]);
  const [years, setYears] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedPanchayat, setSelectedPanchayat] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedWorkType, setSelectedWorkType] = useState("");
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("md"));

  useEffect(() => {
    const fetchInitialData = async () => {
      const blocksData = await api.fetchBlocks();
      setBlocks(blocksData);

      const currentYear = new Date().getFullYear();
      const yearsList = Array.from({ length: 10 }, (_, i) => currentYear - i);
      setYears(yearsList);

      const workTypesData = await api.fetchWorkTypes();
      setWorkTypes(workTypesData);
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchPanchayats = async () => {
      if (selectedBlock) {
        const panchayatsData = await api.fetchPanchayats(selectedBlock);
        setPanchayats(panchayatsData);
        setSelectedPanchayat("");
        setSelectedVillage("");
      } else {
        setPanchayats([]);
        setSelectedPanchayat("");
        setSelectedVillage("");
      }
    };
    fetchPanchayats();
  }, [selectedBlock]);

  useEffect(() => {
    const fetchVillages = async () => {
      if (selectedBlock && selectedPanchayat) {
        const villagesData = await api.fetchVillages(
          selectedBlock,
          selectedPanchayat
        );
        setVillages(villagesData);
        setSelectedVillage("");
      } else {
        setVillages([]);
        setSelectedVillage("");
      }
    };
    fetchVillages();
  }, [selectedBlock, selectedPanchayat]);

  const handleBlockChange = (event) => {
    setSelectedBlock(event.target.value);
  };

  const handlePanchayatChange = (event) => {
    setSelectedPanchayat(event.target.value);
  };

  const handleVillageChange = (event) => {
    setSelectedVillage(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleWorkTypeChange = (event) => {
    setSelectedWorkType(event.target.value);
  };

  const filters = {
    block: selectedBlock,
    panchayat: selectedPanchayat,
    village: selectedVillage,
    year: selectedYear,
    work_type: selectedWorkType,
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
              PRD Dashboard
            </Typography>
            <Button
              component={Link}
              to="/geotag"
              variant="contained"
              color="secondary"
              sx={{ ml: 2 }}
            >
              NREGA Dashboard
            </Button>
          </Toolbar>
        </AppBar>
        <Container
          maxWidth={false}
          sx={{ width: "80%", mt: 4, mb: 4, flexGrow: 1 }}
        >
          <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth>
                  <InputLabel>Block</InputLabel>
                  <Select
                    value={selectedBlock}
                    onChange={handleBlockChange}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">All Blocks</MenuItem>
                    {blocks.map((block) => (
                      <MenuItem key={block} value={block}>
                        {block}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth disabled={!selectedBlock}>
                  <InputLabel>Panchayat</InputLabel>
                  <Select
                    value={selectedPanchayat}
                    onChange={handlePanchayatChange}
                  >
                    <MenuItem value="">All Panchayats</MenuItem>
                    {panchayats.map((panchayat) => (
                      <MenuItem key={panchayat} value={panchayat}>
                        {panchayat}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth disabled={!selectedPanchayat}>
                  <InputLabel>Village</InputLabel>
                  <Select
                    value={selectedVillage}
                    onChange={handleVillageChange}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">All Villages</MenuItem>
                    {villages.map((village) => (
                      <MenuItem key={village} value={village}>
                        {village}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select value={selectedYear} onChange={handleYearChange}>
                    <MenuItem value="">All Years</MenuItem>
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <FormControl fullWidth>
                  <InputLabel>Work Type</InputLabel>
                  <Select
                    value={selectedWorkType}
                    onChange={handleWorkTypeChange}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">All Work Types</MenuItem>
                    {workTypes.map((workType) => (
                      <MenuItem key={workType} value={workType}>
                        {workType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 3, height: "100%" }}>
                <TotalWorks filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={9}>
              <Paper sx={{ p: 3, height: "100%" }}>
                <FinancialSummary filters={filters} />
              </Paper>
            </Grid>

            {/* Blocks and Panchayats charts */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, height: isSmallScreen ? "auto" : 400 }}>
                <BlocksExpenditureChart filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, height: isSmallScreen ? "auto" : 400 }}>
                <TopPanchayatsChart filters={filters} />
              </Paper>
            </Grid>

            {/* Year-wise charts */}
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, height: isSmallScreen ? "auto" : 500 }}>
                <YearWiseWorkCountChart filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, height: isSmallScreen ? "auto" : 500 }}>
                <YearWiseWorkAmountChart filters={filters} />
              </Paper>
            </Grid>

            {/* Rest of the charts */}
            <Grid item xs={12}>
              <Paper sx={{ p: 3, height: 500 }}>
                <WorkTypeChart filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 3, height: 500 }}>
                <WorkTypesByAmountChart filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <FundingDepartmentCharts filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 3, height: isSmallScreen ? "auto" : 1000 }}>
                <MajorSchemeCharts filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, height: isSmallScreen ? "auto" : 400 }}>
                <ConvergenceStatusChart filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3, height: isSmallScreen ? "auto" : 400 }}>
                <ApprovalAgenciesChart filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 3, height: isSmallScreen ? "auto" : 500 }}>
                <StatusByYearChart filters={filters} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <PaymentRecipientsSummary filters={filters} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;
