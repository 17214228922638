import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import api from "../services/api";

function YearWiseWorkCountChart({ filters }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.fetchYearWiseWorkCount(filters);
        setData(result);
      } catch (error) {
        console.error("Error fetching year-wise work count:", error);
        setData([]);
      }
    };
    fetchData();
  }, [filters]);

  return (
    <Box height={400}>
      <Typography variant="h6" gutterBottom>
        Year-wise Work Count
      </Typography>
      {data.length > 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="Year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="Work_Count"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Typography>No data available</Typography>
      )}
    </Box>
  );
}

export default YearWiseWorkCountChart;
