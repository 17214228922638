import React from "react";
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  CircularProgress,
  Modal,
} from "@mui/material";

function ImageWithFallback({ src, alt, ...props }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  const handleLoad = () => {
    setLoading(false);
  };

  if (error) {
    return <Typography>Image not available</Typography>;
  }

  return (
    <Box position="relative">
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      <img
        src={src}
        alt={alt}
        onError={handleError}
        onLoad={handleLoad}
        style={{ display: loading ? "none" : "block" }}
        {...props}
      />
    </Box>
  );
}

function GeotagWorkDetails({
  selectedPanchayat,
  workDetails,
  loading,
  selectedImage,
  handleImageClick,
  handleCloseModal,
}) {
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={400}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!selectedPanchayat || workDetails.length === 0) {
    return null;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? "Invalid Date" : date.toLocaleDateString("en-IN");
  };

  return (
    <>
      <Paper sx={{ p: 2, mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          Work Details for {selectedPanchayat}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: 600, overflow: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Work Code</TableCell>
                <TableCell>Work Name</TableCell>
                <TableCell>Nature of Work</TableCell>
                <TableCell>Work Purpose Status</TableCell>
                <TableCell>Estimated Cost</TableCell>
                <TableCell>Estimated Completion Time</TableCell>
                <TableCell>Work Start Date</TableCell>
                <TableCell>Expenditure (Unskilled)</TableCell>
                <TableCell>Expenditure (Material)</TableCell>
                <TableCell>Total Expenditure</TableCell>
                <TableCell>Photo 1</TableCell>
                <TableCell>Photo 2</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workDetails.map((work) => (
                <TableRow key={work.workcode}>
                  <TableCell>{work.workcode}</TableCell>
                  <TableCell>{work.work_name}</TableCell>
                  <TableCell>{work.nature_of_work}</TableCell>
                  <TableCell>{work.work_purpose_status}</TableCell>
                  <TableCell>₹{work.estimated_cost}</TableCell>
                  <TableCell>{work.estimated_completion_time}</TableCell>
                  <TableCell>{formatDate(work.work_start_date)}</TableCell>
                  <TableCell>₹{work.expenditure_unskilled}</TableCell>
                  <TableCell>₹{work.expenditure_material}</TableCell>
                  <TableCell>₹{work.expenditure_total}</TableCell>
                  <TableCell>
                    <ImageWithFallback
                      src={work.path1}
                      alt="Photo 1"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(work.path1)}
                    />
                  </TableCell>
                  <TableCell>
                    <ImageWithFallback
                      src={work.path2}
                      alt="Photo 2"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(work.path2)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Modal
        open={Boolean(selectedImage)}
        onClose={handleCloseModal}
        aria-labelledby="image-modal"
        aria-describedby="full-size-image"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <ImageWithFallback
            src={selectedImage}
            alt="Full-size image"
            style={{ maxWidth: "100%", maxHeight: "90vh" }}
          />
        </Box>
      </Modal>
    </>
  );
}

export default GeotagWorkDetails;
