import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
} from "@mui/material";

function DetailView({ title, data, onBack }) {
  const formatAmount = (value) => {
    if (typeof value === "string") {
      value = value.replace(/[^\d.-]/g, "");
    }
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <Box>
      <Button onClick={onBack}>Back to Chart</Button>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 400, overflow: "auto" }}
      >
        <Table stickyHeader aria-label="details table">
          <TableHead>
            <TableRow>
              <TableCell>Block</TableCell>
              <TableCell>Gram Panchayat</TableCell>
              <TableCell>Village</TableCell>
              <TableCell>Work Type</TableCell>
              <TableCell>Work Description</TableCell>
              <TableCell align="right">Work Cost</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>AS Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.Block}</TableCell>
                <TableCell>{row.Gram_Panchayat}</TableCell>
                <TableCell>{row.Village}</TableCell>
                <TableCell>{row.Work_Type}</TableCell>
                <TableCell>{row.Work_Description_English}</TableCell>
                <TableCell align="right">
                  {formatAmount(row.Work_Cost)}
                </TableCell>
                <TableCell>{row.Status}</TableCell>
                <TableCell>{row.AS_Date || "N/A"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default DetailView;
