import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import api from "../services/api";

function YearWiseWorkAmountChart({ filters }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.fetchYearWiseWorkAmount(filters);
        console.log("Year-wise work amount data:", result);
        setData(result);
      } catch (error) {
        console.error("Error fetching year-wise work amount:", error);
        setData([]);
      }
    };
    fetchData();
  }, [filters]);

  const formatToCrores = (value) => {
    return (value / 10000000).toFixed(2);
  };

  const calculateYAxisDomain = () => {
    if (data.length === 0) return [0, 1];
    const maxAmount = Math.max(...data.map((item) => item.Total_Amount));
    const minAmount = Math.min(...data.map((item) => item.Total_Amount));
    const padding = (maxAmount - minAmount) * 0.1; // Add 10% padding
    return [Math.max(0, minAmount - padding), maxAmount + padding];
  };

  if (data.length === 0) {
    return (
      <Box height={400}>
        <Typography variant="h6" gutterBottom>
          Year-wise Work Amount
        </Typography>
        <Typography>No data available for the selected filters.</Typography>
      </Box>
    );
  }

  return (
    <Box height={400}>
      <Typography variant="h6" gutterBottom>
        Year-wise Work Amount
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Year"
            label={{ value: "Year", position: "bottom", offset: 30 }}
          />
          <YAxis
            tickFormatter={(value) => `₹${formatToCrores(value)}Cr`}
            domain={calculateYAxisDomain()}
            label={{
              value: "",
              angle: -90,
              position: "insideLeft",
              offset: 10,
            }}
          />
          <Tooltip
            formatter={(value) => `₹${formatToCrores(value)} Crores`}
            labelFormatter={(label) => `Year: ${label}`}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="Total_Amount"
            stroke="#82ca9d"
            activeDot={{ r: 8 }}
            name="Total Amount"
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default YearWiseWorkAmountChart;
