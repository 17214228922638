import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
  Cell,
} from "recharts";
import api from "../services/api";
import DetailView from "./DetailView";

function BlocksExpenditureChart({ filters }) {
  const [data, setData] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [detailedData, setDetailedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.fetchBlocksByExpenditure(filters);
        const sortedData = result
          .map((item) => ({
            ...item,
            Total_Expenditure: Number(item.Total_Expenditure) || 0,
            color: "#34b7eb",
          }))
          .sort((a, b) => b.Total_Expenditure - a.Total_Expenditure);
        setData(sortedData);
      } catch (error) {
        console.error("Error fetching blocks expenditure data:", error);
        setData([]);
      }
    };
    fetchData();
  }, [filters]);

  useEffect(() => {
    if (selectedBlock) {
      const fetchDetailedData = async () => {
        try {
          const result = await api.fetchBlockExpenditureDetails({
            ...filters,
            block: selectedBlock,
          });
          setDetailedData(result);
        } catch (error) {
          console.error("Error fetching detailed block data:", error);
          setDetailedData([]);
        }
      };
      fetchDetailedData();
    }
  }, [selectedBlock, filters]);

  const formatToCrores = (value) => {
    if (typeof value !== "number" || isNaN(value)) {
      return "0.00";
    }
    return (value / 10000000).toFixed(2);
  };

  const handleClick = (entry) => {
    setSelectedBlock(entry.Block);
  };

  const handleBack = () => {
    setSelectedBlock(null);
  };

  if (selectedBlock) {
    return (
      <DetailView
        title={`Details for Block: ${selectedBlock}`}
        data={detailedData}
        onBack={handleBack}
      />
    );
  }

  return (
    <Box height={300}>
      <Typography variant="h6" gutterBottom sx={{ fontSize: "0.9rem" }}>
        Blocks by Expenditure
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 40, bottom: 60 }}
          barSize={20}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Block"
            angle={-45}
            textAnchor="end"
            interval={0}
            height={80}
            tick={{ fontSize: "0.6rem" }}
          />
          <YAxis
            tickFormatter={(value) => `${formatToCrores(value)}Cr`}
            tick={{ fontSize: "0.7rem" }}
          >
            <Label
              value="Expenditure (Crores)"
              position="left"
              angle={-90}
              style={{ textAnchor: "middle", fontSize: "0.7rem" }}
              offset={10}
            />
          </YAxis>
          <Tooltip
            formatter={(value) => [
              `₹${formatToCrores(value)} Crores`,
              "Total Expenditure",
            ]}
          />
          <Legend wrapperStyle={{ fontSize: "0.7rem" }} />
          <Bar
            dataKey="Total_Expenditure"
            name="Total Expenditure"
            onClick={handleClick}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
            <LabelList
              dataKey="Total_Expenditure"
              position="top"
              formatter={formatToCrores}
              style={{ fontSize: "0.6rem", fill: "black" }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default BlocksExpenditureChart;
