import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

function GeotagSummary({ summary }) {
  return (
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Total Entries</Typography>
          <Typography variant="h4">{summary.total_entries || 0}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Unique Workcodes</Typography>
          <Typography variant="h4">{summary.unique_workcodes || 0}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Unique Blocks</Typography>
          <Typography variant="h4">{summary.unique_blocks || 0}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Unique Panchayats</Typography>
          <Typography variant="h4">{summary.unique_panchayats || 0}</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default GeotagSummary;
