import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, Paper } from "@mui/material";
import api from "../services/api";

function FinancialSummary({ filters }) {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.fetchFinancialSummary(filters);
      setData(result);
    };
    fetchData();
  }, [filters]);

  const formatAmount = (value) => {
    const amountInCrores = value / 10000000; // Convert to crores
    return new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(amountInCrores);
  };

  const KPIBox = ({ title, value }) => (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h5" color="primary" sx={{ fontWeight: "bold" }}>
        ₹{formatAmount(value)} Cr
      </Typography>
    </Paper>
  );

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Financial Summary (in Crores)
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <KPIBox title="Total Work Cost" value={data.Total_Work_Cost || 0} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <KPIBox
            title="TS Sanctioned Amount"
            value={data.Total_TS_Sanctioned_Amount || 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <KPIBox title="Scheme Amount" value={data.Total_Scheme_Amount || 0} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <KPIBox
            title="Scheme Total Amount"
            value={data.Total_Scheme_Total_Amount || 0}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default FinancialSummary;
