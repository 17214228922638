import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Chip,
  Divider,
  Modal,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import { FilterList, Close } from "@mui/icons-material";
import GeotagFilters from "./GeotagFilters";
import GeotagSummary from "./GeotagSummary";
import GeotagMap from "./GeotagMap";
import GeotagWorkDetails from "./GeotagWorkDetails";
import WorkStatusAndKPIs from "./WorkStatusAndKPIs";
import ExpenditureSummaryChart from "./ExpenditureSummaryChart";
import api from "../services/api";

function GeotagDashboard() {
  const [mapData, setMapData] = useState([]);
  const [summary, setSummary] = useState({});
  const [workDetails, setWorkDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [panchayats, setPanchayats] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedPanchayat, setSelectedPanchayat] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [mapCenter, setMapCenter] = useState([23.5937, 78.9629]);
  const [mapZoom, setMapZoom] = useState(7);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchInitialData = useCallback(async () => {
    try {
      setLoading(true);
      const [yearsData, blocksData] = await Promise.all([
        api.fetchGeotagYears(),
        api.fetchGeotagBlocks(),
      ]);
      setYears(yearsData);
      setBlocks(blocksData);
    } catch (error) {
      console.error("Error fetching initial geotag data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPanchayats = useCallback(async () => {
    if (selectedBlock) {
      try {
        setLoading(true);
        const panchayatsData = await api.fetchGeotagPanchayats(selectedBlock);
        setPanchayats(panchayatsData);
      } catch (error) {
        console.error("Error fetching panchayats:", error);
        setPanchayats([]);
      } finally {
        setLoading(false);
      }
    } else {
      setPanchayats([]);
    }
  }, [selectedBlock]);

  const fetchMapData = useCallback(async () => {
    try {
      setLoading(true);
      const mapDataResult = await api.fetchGeotagWorkData(
        selectedYear,
        selectedBlock,
        selectedPanchayat,
        page
      );
      setMapData((prevData) =>
        page === 1 ? mapDataResult : [...prevData, ...mapDataResult]
      );
      setHasMore(mapDataResult.length > 0);

      if (mapDataResult.length > 0 && page === 1) {
        if (selectedPanchayat || selectedBlock) {
          setMapCenter([mapDataResult[0].lat, mapDataResult[0].lon]);
          setMapZoom(12);
        } else {
          setMapCenter([23.5937, 78.9629]);
          setMapZoom(7);
        }
      }
    } catch (error) {
      console.error("Error fetching geotag map data:", error);
    } finally {
      setLoading(false);
    }
  }, [selectedYear, selectedBlock, selectedPanchayat, page]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [summaryResult] = await Promise.all([
        api.fetchGeotagSummary(selectedYear, selectedBlock, selectedPanchayat),
      ]);

      setSummary(summaryResult);

      if (selectedPanchayat) {
        const workDetailsResult = await api.fetchGeotagWorkDetails(
          selectedPanchayat,
          selectedYear
        );
        setWorkDetails(workDetailsResult);
      } else {
        setWorkDetails([]);
      }
    } catch (error) {
      console.error("Error fetching geotag data:", error);
    } finally {
      setLoading(false);
    }
  }, [selectedYear, selectedBlock, selectedPanchayat]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    fetchPanchayats();
  }, [fetchPanchayats]);

  useEffect(() => {
    setPage(1);
    setMapData([]);
    fetchData();
    fetchMapData();
  }, [fetchData, fetchMapData, selectedYear, selectedBlock, selectedPanchayat]);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setSelectedBlock("");
    setSelectedPanchayat("");
  };

  const handleBlockChange = (event) => {
    setSelectedBlock(event.target.value);
    setSelectedPanchayat("");
  };

  const handlePanchayatChange = (event) => {
    setSelectedPanchayat(event.target.value);
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const renderActiveFilters = () => {
    const filters = [];
    if (selectedYear) filters.push({ label: "Year", value: selectedYear });
    if (selectedBlock) filters.push({ label: "Block", value: selectedBlock });
    if (selectedPanchayat)
      filters.push({ label: "Panchayat", value: selectedPanchayat });

    if (filters.length === 0) return null;

    return (
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <FilterList sx={{ mr: 1 }} />
        <Typography variant="subtitle1" sx={{ mr: 2 }}>
          Active Filters:
        </Typography>
        {filters.map((filter, index) => (
          <Chip
            key={index}
            label={`${filter.label}: ${filter.value}`}
            onDelete={() => {
              if (filter.label === "Year") setSelectedYear("");
              if (filter.label === "Block") setSelectedBlock("");
              if (filter.label === "Panchayat") setSelectedPanchayat("");
            }}
            sx={{ mr: 1 }}
          />
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        NREGA Dashboard
      </Typography>
      <GeotagFilters
        years={years}
        blocks={blocks}
        panchayats={panchayats}
        selectedYear={selectedYear}
        selectedBlock={selectedBlock}
        selectedPanchayat={selectedPanchayat}
        onYearChange={handleYearChange}
        onBlockChange={handleBlockChange}
        onPanchayatChange={handlePanchayatChange}
      />
      {renderActiveFilters()}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <GeotagSummary summary={summary} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <WorkStatusAndKPIs
                  year={selectedYear}
                  block={selectedBlock}
                  panchayat={selectedPanchayat}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 2, height: 400 }}>
                <ExpenditureSummaryChart
                  year={selectedYear}
                  block={selectedBlock}
                  panchayat={selectedPanchayat}
                />
              </Paper>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <Paper
              elevation={3}
              sx={{
                width: "60%",
                height: 500,
                p: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#e3f2fd",
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ color: "#3f51b5" }}>
                Geotag Map
              </Typography>
              <Box sx={{ width: "100%", height: "100%" }}>
                <GeotagMap
                  mapData={mapData}
                  center={mapCenter}
                  zoom={mapZoom}
                />
              </Box>
            </Paper>
          </Box>
          {hasMore && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button onClick={handleLoadMore} variant="contained">
                Load More
              </Button>
            </Box>
          )}
          <Divider sx={{ my: 4 }} />
          <Typography variant="h5" gutterBottom sx={{ color: "#3f51b5" }}>
            Work Details
          </Typography>
          <GeotagWorkDetails
            selectedPanchayat={selectedPanchayat}
            workDetails={workDetails}
            loading={loading}
            selectedImage={selectedImage}
            handleImageClick={handleImageClick}
            handleCloseModal={handleCloseModal}
          />
          <Modal open={!!selectedImage} onClose={handleCloseModal}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 8, right: 8 }}
                onClick={handleCloseModal}
              >
                <Close />
              </IconButton>
              <img
                src={selectedImage}
                alt="Selected"
                style={{ width: "100%", borderRadius: 8 }}
              />
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
}

export default GeotagDashboard;
