import React, { useState, useEffect } from "react";
import { Typography, Box, Paper, Grid } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import api from "../services/api";
import DetailView from "./DetailView";

function MajorSchemeCharts({ filters }) {
  const [countData, setCountData] = useState([]);
  const [amountData, setAmountData] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState(null);
  const [detailedData, setDetailedData] = useState([]);
  const [viewType, setViewType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countResult = await api.fetchMajorSchemeCount(filters);
        setCountData(
          countResult.map((item) => ({
            ...item,
            Count: Number(item.Count) || 0,
            Percentage: Number(item.Percentage) || 0,
          }))
        );

        const amountResult = await api.fetchMajorSchemeAmount(filters);
        setAmountData(
          amountResult.map((item) => ({
            ...item,
            Total_Sanctioned_Amount: Number(item.Total_Sanctioned_Amount) || 0,
            Percentage: Number(item.Percentage) || 0,
          }))
        );
      } catch (error) {
        console.error("Error fetching major scheme data:", error);
        setCountData([]);
        setAmountData([]);
      }
    };
    fetchData();
  }, [filters]);

  useEffect(() => {
    if (selectedScheme) {
      const fetchDetailedData = async () => {
        try {
          const result = await (viewType === "count"
            ? api.fetchMajorSchemeCountDetails
            : api.fetchMajorSchemeAmountDetails)({
            ...filters,
            major_scheme: selectedScheme,
          });
          setDetailedData(result);
        } catch (error) {
          console.error("Error fetching detailed data:", error);
          setDetailedData([]);
        }
      };
      fetchDetailedData();
    }
  }, [selectedScheme, filters, viewType]);

  const handleClick = (entry, type) => {
    setSelectedScheme(entry.Major_Scheme);
    setViewType(type);
  };

  const handleBack = () => {
    setSelectedScheme(null);
    setViewType(null);
  };

  const formatValue = (value) => {
    return typeof value === "number" ? value.toFixed(2) : "0.00";
  };

  const renderChart = (data, title, dataKey, type) => (
    <Box height={400}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Paper elevation={3} sx={{ p: 2, height: "100%" }}>
        {data.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              layout="vertical"
              margin={{ top: 5, right: 30, left: 200, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis
                dataKey="Major_Scheme"
                type="category"
                width={190}
                tick={{ fontSize: "0.8rem" }}
              />
              <Tooltip formatter={(value) => `${formatValue(value)}%`} />
              <Legend />
              <Bar
                dataKey="Percentage"
                fill="#82ca9d"
                onClick={(entry) => handleClick(entry, type)}
              >
                <LabelList
                  dataKey="Percentage"
                  position="right"
                  formatter={(value) => `${formatValue(value)}%`}
                  style={{ fontSize: "0.8rem" }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Typography variant="body1">
            No data available for the selected filters.
          </Typography>
        )}
      </Paper>
    </Box>
  );

  if (selectedScheme) {
    return (
      <DetailView
        title={`Details for Major Scheme: ${selectedScheme}`}
        data={detailedData}
        onBack={handleBack}
      />
    );
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Major Scheme Distribution
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {renderChart(countData, "By Count", "Count", "count")}
        </Grid>
        <Grid item xs={12}>
          {renderChart(
            amountData,
            "By Amount",
            "Total_Sanctioned_Amount",
            "amount"
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default MajorSchemeCharts;
