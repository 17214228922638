// Update the TopPanchayatsChart component (src/components/TopPanchayatsChart.js)

import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
  Cell,
} from "recharts";
import api from "../services/api";
import DetailView from "./DetailView";

function TopPanchayatsChart({ filters }) {
  const [data, setData] = useState([]);
  const [selectedPanchayat, setSelectedPanchayat] = useState(null);
  const [detailedData, setDetailedData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.fetchTopPanchayatsByExpenditure(filters);
      const sortedData = result.sort(
        (a, b) => b.Total_Expenditure - a.Total_Expenditure
      );
      setData(
        sortedData.map((item) => ({
          ...item,
          color: "#34b7eb",
        }))
      );
    };
    fetchData();
  }, [filters]);

  useEffect(() => {
    if (selectedPanchayat) {
      const fetchDetailedData = async () => {
        const result = await api.fetchPanchayatExpenditureDetails({
          ...filters,
          panchayat: selectedPanchayat,
        });
        setDetailedData(result);
      };
      fetchDetailedData();
    }
  }, [selectedPanchayat, filters]);

  const formatToCrores = (value) => {
    return (value / 10000000).toFixed(2);
  };

  const handleClick = (entry) => {
    setSelectedPanchayat(entry.Gram_Panchayat);
  };

  const handleBack = () => {
    setSelectedPanchayat(null);
  };

  if (selectedPanchayat) {
    return (
      <DetailView
        title={`Details for Panchayat: ${selectedPanchayat}`}
        data={detailedData}
        onBack={handleBack}
      />
    );
  }

  return (
    <Box height={300}>
      <Typography variant="h6" gutterBottom sx={{ fontSize: "0.9rem" }}>
        Top 10 Panchayats by Expenditure
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 40, bottom: 60 }}
          barSize={20}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Gram_Panchayat"
            angle={-45}
            textAnchor="end"
            interval={0}
            height={80}
            tick={{ fontSize: "0.6rem" }}
          />
          <YAxis
            tickFormatter={(value) => `${formatToCrores(value)}Cr`}
            tick={{ fontSize: "0.7rem" }}
          >
            <Label
              value="Expenditure (Crores)"
              position="left"
              angle={-90}
              style={{ textAnchor: "middle", fontSize: "0.7rem" }}
              offset={10}
            />
          </YAxis>
          <Tooltip formatter={(value) => `₹${formatToCrores(value)} Crores`} />
          <Legend wrapperStyle={{ fontSize: "0.7rem" }} />
          <Bar
            dataKey="Total_Expenditure"
            name="Total Expenditure"
            onClick={handleClick}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
            <LabelList
              dataKey="Total_Expenditure"
              position="top"
              formatter={formatToCrores}
              style={{ fontSize: "0.6rem", fill: "black" }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default TopPanchayatsChart;
