import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";

// Fix for default marker icon
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

function GeotagMap({ mapData, center, zoom }) {
  const mapRef = useRef(null);
  const markerClusterRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(center, zoom);
    }
  }, [center, zoom]);

  useEffect(() => {
    if (markerClusterRef.current) {
      markerClusterRef.current.clearLayers();
      mapData.forEach((point) => {
        const marker = L.marker([point.lat, point.lon]);
        marker.bindPopup(`
          <div>
            <p><strong>Block:</strong> ${point.block_name}</p>
            <p><strong>Panchayat:</strong> ${point.gpname}</p>
            <p><strong>Work Code:</strong> ${point.workcode}</p>
            <p><strong>Work Name:</strong> ${point.work_name}</p>
            <p><strong>Nature of Work:</strong> ${point.nature_of_work}</p>
            <p><strong>Status:</strong> ${point.work_purpose_status}</p>
            <p><strong>Estimated Cost:</strong> ₹${point.estimated_cost.toLocaleString(
              "en-IN"
            )}</p>
            <p><strong>Total Expenditure:</strong> ₹${point.expenditure_total.toLocaleString(
              "en-IN"
            )}</p>
          </div>
        `);
        markerClusterRef.current.addLayer(marker);
      });
    }
  }, [mapData]);

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      style={{ height: "100%", width: "100%" }}
      ref={mapRef}
    >
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satellite">
          <TileLayer
            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://opentopomap.org">OpenTopoMap</a> contributors'
          />
        </LayersControl.BaseLayer>
      </LayersControl>
      <MarkerClusterGroup
        ref={markerClusterRef}
        chunkedLoading
        maxClusterRadius={80}
        spiderfyOnMaxZoom={true}
        showCoverageOnHover={false}
      />
    </MapContainer>
  );
}

export default GeotagMap;
